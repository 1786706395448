import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

// Pages import Here 
import Welcome from "./pages/Welcome";
import Error from "./pages/Error";
import Contact from "./elements/contact/Contact";
import Agency from "./pages/WebAgency";
import Home from "./pages/HomeDefault";
import Finance from "./pages/Finance";
import Business from "./pages/Business";

// Import Css Here 
import './assets/scss/style.scss';

function App() { 
        useEffect(() => {   

         }, []);   
        return ( 
            <>
            <BrowserRouter>            
                <Routes>
                     <Route path="/"  element={ <Welcome /> }/> 
                     <Route path="/contact"  element={ <Contact /> }/> 
                     <Route path="/agency"  element={ <Agency /> }/>
                     <Route path="/home"  element={ <Home /> }/>
                     <Route path="/business"  element={ <Business /> }/> 
                     <Route path="/finance"  element={ <Finance /> }/>                     
                     <Route path="*"  element={ <Error /> }/>
                </Routes>             
            </BrowserRouter>                
            </>
        )
    
    }

export default App;

import React, { Component } from 'react';
import { Link } from "react-router-dom";

class QRcontact extends Component {
         render() {
                return (       
                        <div className="row row--15">
                            <div className="col-lg-12">
                                <div className="rn-contact-address mt_dec--30">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="rn-address">
                                                <div className="icon">
                                                <img src="./images/demo/high-quality.png" alt="high-quality" />
                                                </div>
                                                <div className="inner">
                                                    <h4 className="title">Line ID:</h4>
                                                    <p><Link href="#">@1234</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="rn-address">
                                                <div className="icon">
                                                <img src="./images/demo/high-quality.png" alt="high-quality" />
                                                </div>
                                                <div className="inner">
                                                    <h4 className="title">Telegram ID:</h4>
                                                    <p><Link href="$">097 246 6899</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>       
                    );
         }
}
export default QRcontact;
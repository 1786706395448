import React from 'react'
import 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <div className={`rn-accordion-style ${customStyle}`}>
            <div className='accordion' >
            <div className='accordion-item'>
                <div className='accordion-header' id="h0">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="b0" aria-expanded="true" aria-controls="b0">
                    What is Doob ? How does it work?
                </button>
                </div>
                <div id="b0" class="accordion-collapse collapse show" aria-labelledby="h0" data-bs-parent="#my_accordion">
                <div class="accordion-body">
                    Welcome to Doob React Creative Agency, React Portfolio and Corporate Multi Purpose Template Built With React JS. NO jQuery!. </div>
                </div>
            </div>

            <div className='accordion-item'>
            <div className='accordion-header' id="h1">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="b1" aria-expanded="true" aria-controls="b1">
                    How can I run doob react template?
                </button>
                </div>
                <div id="b1" class="accordion-collapse collapse show" aria-labelledby="h1" data-bs-parent="#my_accordion">
                <div class="accordion-body">
                    You can run doob easily. First You'll need to have node and npm on your machine. So Please open your command prompt then check your node -v and npm -v Version. Goes To Your your command prompt: then First: npm install

                    At Last: npm run start. By the following way you can be run your project easily.
                    </div>
                </div>
            </div>

            <div className='accordion-item'>
            <div className='accordion-header' id="h2">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="b2" aria-expanded="true" aria-controls="b2">
                    How can I get the customer support?
                </button>
                </div>
                <div id="b2" class="accordion-collapse collapse show" aria-labelledby="h2" data-bs-parent="#my_accordion">
                <div class="accordion-body">
                    After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.
                    </div>
                </div>
            </div>

        </div>
        </div>
    )
}
export default AccordionOne


import React from 'react';
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Logo from "../logo/Logo";

const callToActionData = {
    title: "Ready to start creating Web Application.",
    subtitle: "CrazyDev คือทีมผู้เชี่ยวชาญการพัฒนาระบบเว็บแอปพลิเคชั่น เพื่อส่งเสริมต่อยอดให้ธุรกิจของคุณเติบโตได้อย่างทันสมัย",
    btnText: "สนใจติดต่อ",
}

const CalltoActionSeven = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-start">
                                <Logo 
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                                />
                                
                                
                                    <p className="subtitle">{callToActionData.subtitle}</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                       
                        
                            <div className="call-to-btn text-start mt_sm--20 text-md-end">
                            <Link className="btn-default btn-icon" target="_blank" href="#">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSeven;
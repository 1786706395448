import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterThree from '../common/footer/FooterThree';
import { FiAirplay } from "react-icons/fi";

const Demo = [
    {
        Url : "/web-design",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Web Design",
        badge: "",
    },

    {
        Url : "/php",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "PHP Project",
        badge: "",
    },
    {
        Url : "/laravel",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Laravel Project",
        badge: "",
    },
    {
        Url : "/react-js",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "React-JS Project",
        badge: "",
    },   
    {
        Url : "/next-js",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Next-JS Project",
        badge: "",
    },
    
    {
        Url : "mobile-app",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Mobile App",
        badge: "",
    },
    {
        Url : "wordpress",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Wordpress",
        badge: "",
    },
    
    {
        Url : "Other",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "Other",
        badge: "",
    },
    {
        Url : "all-demo",
        Image: "./images/demo/coming-soon.png" ,
        Imagelight: "./images/demo/coming-soon.png" ,
        Title: "All Demo",
        badge: "",
    },

]



const Welcome = () => {
    return (
        <>
           <SEO title="Demo" />
           <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div className="react-image mb--20">
                                        <img src="./images/demo/high-quality.png" alt="high-quality" />
                                    </div>
                                    <h1 className="title display-two">All-in-Dev
                                    <br/>
                                        <span className="theme-gradient"> Web Development</span>   & {" "}   
                                        <br /> 
                                        Business Consulting.                                         
                                    </h1>
                                    <p className="description"></p>
                                    <div className="button-group">
                                        <a className="btn-default btn-large round btn-icon" href="/home">Main Website <FiAirplay /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Our Demo  */}
                <div className="rn-demo-area rn-section-gap" id="alldemo">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle                                  
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "My Portfolio"
                                    title = "The Demo Project"
                                    description = "เรารับจ้างออกแบบดูแลระบบเว็บไซต์ทุกประเภท เลือกภาษาได้หลากหลายรูปแบบตามความเหมาะสมของธุรกิจ พร้อมรองรับการใช้งานทุกอุปกรณ์"
                                />
                            </div>
                        </div>
                        <div className="row row--30">
                            {Demo.map((data, index) => (
                                <div className="col-lg-4 col-md-6 col-12 mt--40" key={index}>
                                    <div className="single-demo">
                                        <Link to={`${data.Url}`}>
                                            <div className="thumbnail">
                                                <img className="image-dark" src={`${data.Image}`} alt="Corporate Images" />
                                                <img className="image-light" src={`${data.Imagelight}`} alt="Corporate Images" />
                                                {data.badge ? <span className="label-badge">{data.badge}</span> : ''}
                                            </div>
                                        </Link>
                                        <h4 className="title"><Link to={`${data.Url}`}>{data.Title}</Link> </h4>
                                    </div>
                                </div>
                            ))} 
                        </div>
                    </div>
                </div> 
                {/* End Our Demo  */}


                <Separator />

                {/* Start Inner Pages  */}
                <div className="rn-inner-pages rn-section-gap">
                    <div className="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">                              

                    </div>
                </div>
                {/* End Inner Pages  */}
                <FooterThree />
           </main>

        </>
    )
}

export default Welcome;